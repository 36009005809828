import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>IRONMAN 70.3 Chattanooga Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN 70.3 Chattanooga Race Report" />
        <meta
          property="og:title"
          content="IRONMAN 70.3 Chattanooga Race Report"
        />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/chatt703-2023-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/chatt703-2023-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/chatt703-2023-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            IRONMAN 70.3 Chattanooga Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">May 21, 2023</p>
            <p className="lead mb-0">Chattanooga, TN</p>
          </div>
        </div>

        <div className="px-4 py-4">
          <p>
            Full disclosure: This race report touches on several difficult
            events away from triathlon. Recency bias aside, these days are some
            of the most unforgettable of my life, both for the good and the bad.
            What follows isn't a light read.
          </p>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            My 2022 race season went off the rails after I caught COVID in late
            June and then again in early August. I still managed to drag my way
            through Ironman 70.3 Augusta at the end of September, but I felt
            like shit that day and my time reflected it.
          </p>

          <p>
            During last year's season, I had hoped to finish a 70.3 in under six
            hours for the first time, but I didn't get there. Gulf Coast lost
            its swim due to severe weather, it didn't happen in Chattanooga, and
            it certainly never was in the cards in Augusta.
          </p>

          <p>
            There's no reason to rehash it here since it's already out there on
            social media, but I suppose it's worth mentioning for the sake of
            completeness.&nbsp;
            <a href="https://www.instagram.com/p/CsYc0JruyvR/" target="_blank">
              Katy had a seizure on Thanksgiving Day
            </a>
            &nbsp;that involved a terrifying few minutes where she stopped
            breathing, and I had to give her CPR. It took four months until we
            were sure it wouldn't happen again. It was hard to keep training
            during that time. There were days I wondered if I should still
            pursue this sport.
          </p>

          <p>
            As winter gave way to spring, finishing a 70.3 in under six hours
            remained an important goal, but this race in Chattanooga became
            about so much more. I wanted redemption from a lousy 2022 season. I
            also wanted validation that life had thrown a whole bunch of
            bullshit my way, and I'd fought through it all.
          </p>

          <p>
            I'm not saying I'm happy I got sick twice, but if COVID doesn't
            wreck the second half of the year, I don't believe everything I'm
            about to write happens. It lit a fire under me that left me angry
            and motivated. If I'd had a moderately successful race in Augusta, I
            think I would have had another moderately successful race in
            Chattanooga.
          </p>

          <p>Instead, I had the race of my life – and then some.</p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Pre-Race</h1>
          <p>
            Throughout the winter, Frank had been coaching me, Tiffany, Caitlin,
            Helma, and Bianca. We generally followed the same training plan
            structure and often rode together virtually in Zwift. Each of us
            would be racing in Chattanooga. On those longer training days, it
            helped to know that we were all working towards the same thing.
          </p>
          <p>Frank and Helma are married. Bianca is one of their kids. Tiffany and Caitlin are married. Just a couple of powerhouse triathlon families. After really hard training days, I've sometimes wondered how much their respective homes smell. It's bad enough here in Nashville with just my stink!</p>
          <p>
            I tend to get many jitters in the week leading into a race. My brain
            tries to trick me into thinking I'm getting sick, I'm injured, or
            tries to drown me with doubt. So it was wonderful to share an Airbnb
            with these supportive and uplifting people.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/chattanooga-poster.jpg"
                alt=""
              />
            </figure>
          </div>
          <p>
            There are two moments I'll remember about the days leading into the
            race.
          </p>
          <p>
            The first was when we were having dinner the night before the race.
            I actually remember the sentence verbatim. Caitlin told Katy, “I
            hope you realize that your husband is a really talented triathlete.”
          </p>
          <p>
            It was incredibly kind that she told Katy this, but I'm the one who
            probably needed to hear that message the most. I've been doing this
            sport for almost exactly four years, but I still feel like I'm
            faking it. Everybody else on the team? They are real athletes. I'm
            just the tag-along friend.
          </p>
          <p>
            Is that inaccurate? Probably. But for how I felt that evening, a
            real athlete told my wife I might be one too. That meant a lot.
          </p>
          <p>
            The other moment that stands out to me was when Frank went through
            our race plans with each of us. He's awesome. He gave us specific
            paces to shoot for, average watts, how to manage nutrition, and
            basically everything we needed to perform our best.
          </p>
          <p>He also came with predictions.</p>
          <p>
            My best time in Chattanooga and a 70.3 overall was 6:30. He told me
            he expected me to finish this race in 5:20. I'm sorry. What? When he
            said the number, I let out an exasperated chuckle and literally
            rolled my eyes at him. I agreed with his assessment that it had been
            an offseason full of gains, but this number sounded ludicrous. He
            asked me for my prediction. I told him if everything fell into
            place, I hoped I'd finally clear 6 hours and would probably land
            around 5:50.
          </p>
          <p>
            On a lighter note, for the week leading into the race, I'd been
            under immense pressure to shave my legs. I don't know if it's
            accurate or not, but everyone said leg hair would cost me eight
            minutes over the course of the ride. I finally gave in.
          </p>
          <p>
            Shaving your legs for the first time is one thing. Doing it in front
            of your wife, your triathlon team, and on Instagram Live is
            something else entirely.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/leg-shaving.jpg"
                alt=""
              />
            </figure>
          </div>
          <p>
            We all shared a lot of laughs throughout the process. The Saunders
            even included it in one of their{" "}
            <a href="https://youtu.be/8CivyF3Fh8w?t=319" target="_blank">
              race week episode vlogs.
            </a>
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>The Race</h1>
          <p>
            I got some terrible news from down in Atlanta. Mark had been
            diagnosed with cancer, it was likely terminal, and the timeframe was
            short.
          </p>
          <p>
            Triathlon is a sport of compartmentalization. For example, worrying
            about the bike ride while you're in the middle of the swim doesn't
            serve you well. As horrible as this news sounded, I wanted to
            approach things with the same mindset. There's nothing I can do
            about this right now. I tried to put it out of my mind and focus on
            the day ahead as best I could.
          </p>
          <p>I love the moments just before a race begins.</p>
          <p>
            There is literally nothing left to do. The training is done. The
            packing/traveling is done. Nobody is tired or smelly yet. The day is
            full of potential. It's a unique moment, and you often get treated
            to an awesome sunrise over a body of water.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/pre-swim.jpg"
                alt=""
              />
            </figure>
          </div>
          <p>
            I hugged everybody before we set off for the swim start. DJ got this
            wonderful photo of Tiffany and me. She had her own pile of crap to
            deal with during the 2022 season. I was so excited for her to get
            her redemption race here in Chattanooga too.
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/hug.jpg" alt="" />
            </figure>
          </div>
          <p>
            Of all the photos from this day, this is the one that stands out the
            most to me. This is what this sport is all about.
          </p>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>
          <p>
            Ironman places buoys along the course that start yellow and then
            turn orange before one final red buoy. I got excited about how
            quickly I got to the orange buoys. I didn't look at my watch during
            the swim, but I knew things were going well.
          </p>
          <p>
            When I came out of the water, I heard Frank yelling at me to keep
            kicking ass. Yes, sir!
          </p>
          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/swim-finish.jpg"
                alt=""
              />
            </figure>
          </div>
          <p>
            A 36:27 finishing time at a 1:27/100 yd pace is a personal best for
            me on this course.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>
          <p>
            I ended up running right next to Bianca the whole way up to our
            bikes. It was the first of probably a dozen times we would see each
            other throughout the day. She is snappier with her transitions than
            me. I needed about another 30 seconds compared to her. Something to
            work on in the future!
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>
          <p>
            Frank told me he wanted me to maintain an average power of 207 watts
            throughout the ride. If I'm being honest, that seemed like a high
            number to hold over 56 miles, but I figured I'd try to keep with his
            recommendations as long as I could.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/bike.jpg" alt="" />
            </figure>
          </div>

          <p>
            I biked a personal best for any 70.3 course, clocking in at 2:43.
            Four years ago, when I first raced here, this same ride took 3:44.
            Amazing. My average power for the ride ended up almost exactly where
            Frank asked. He wanted me at 207, and I rode 208. He was right. I
            could maintain it over the whole ride and its 2,200 feet of
            climbing.
          </p>

          <p>
            But most importantly, I had put myself in a great position to
            finally bust through that sub-6 barrier. I felt some fatigue from
            the ride (and the unexpected headwind over the last 20 miles!), but
            I felt great as I returned to town.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>

          <p>
            Off with the helmet, on with the shoes. Nothing eventful here to
            mention. The best kind of T2.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>

          <p>
            My lack of confidence has been visible throughout this race report.
            But I will say this: I believed that if I could get to the start of
            the run without anything like a freak thunderstorm or a mechanical
            issue with my bike, I would bring it home and finish sub-6.
          </p>

          <p>
            That confidence was tested early. There was an uneasy rumbling in my
            gut, and I had a pesky side stitch. Like in the other disciplines,
            Frank had given me a goal pace. He wanted me to run the first half
            at 8:40/mile and accelerate to at least 8:30/mile during the second
            half. Unlike on the bike, I couldn't hold that pace because I didn't
            feel great. I tried to reassure myself that the 9:15/mile I was
            running would still get me to the finish in under 6 hours, as long
            as this didn't worsen.
          </p>

          <p>
            After a little over 6 miles of trying to push through the
            discomfort, I'd started to realize what might help.
          </p>

          <p>
            I emerged from the portapotty at the mile 7 aid station feeling like
            a new man. The cost was about 2 minutes. The result? I felt AWESOME.
            I ran the rest of the race in a nearly perfect negative split,
            starting at 8:37/mile and finishing at 7:22/mile. Maybe I was just
            motivated to run away from the stinkiness of that port-a-potty.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/run.jpg" alt="" />
            </figure>
          </div>

          <p>
            I reached mile 10 at almost precisely the 5-hour mark of the race.
            Holy shit! My goal is 5:59:59, and I am just 3 miles from the finish
            looking at 5:00:00 on my watch. Not that I planned to, but I could{" "}
            <em>walk</em> to the finish and get this done in under 6 hours now.
            It was surreal. I have struggled so many times on these 70.3 half
            marathons, but today I was getting faster as each mile went by.
          </p>
          <p>
            I ran a 1:55 half marathon. Four years ago, it took me 2:54 on this
            course. Just incredible.
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Postrace</h1>

          <p>
            It doesn't matter how many times I have done it. There's nothing
            quite like running down that red carpet.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/finish-line.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            I had made a very conscious decision about what to do when I
            finished. I needed to stop and turn around after crossing the finish
            line. I didn't want to miss my name and time on the board over the
            finish line. This had been a year in the making and I didn't want to
            just see a FinisherPix photo of my first sub-6 70.3. I wanted to see it myself.
          </p>

          <p>5:24:09! Holy shit!</p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/board.jpg" alt="" />
            </figure>
          </div>

          <p>
            Credit where credit is due. Frank had predicted my time within four
            minutes. Meanwhile, I whiffed by half an hour!
          </p>

          <p>
            I went looking for Katy. When I found her, the tears started flowing
            for a minute or so. It wasn't just that I'd obliterated my sub-6
            time goal. This weekend was also her first trip away from Nashville
            since getting a clean bill of health following her seizure.
          </p>

          <p>
            I came dangerously close to an alternate reality where she isn't
            here waiting at the finish line. But she's here and healthy. 🥹 When
            I started thinking about that, oof. There's that lump in my throat
            again. Suffice to say, as elated as I am over how the race went, compared to her health, it doesn't matter at all.
          </p>

          <p>
            This comparison is slightly misleading because COVID wrecked my
            training plan leading into Augusta last September. But in the seven
            months between that race and today, I went from a finishing time of
            6:43 to 5:24. It's beyond anything I ever could have imagined was
            possible.
          </p>

          <p>
            My previous personal best in a 70.3 was 6:30 here in Chattanooga one
            year ago. Compared to today, it's a 66-minute improvement.
          </p>

          <p>What an incredible day. It wasn't over, either.</p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/medals.jpg" alt="" />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Award Ceremony</h1>
          <p>
            I'd completed my goal of finishing in under six hours, but we still
            were waiting to see if Tiffany and Caitlin accomplished their goals
            of snagging slots to the Ironman 70.3 World Championship in Finland
            at the end of August.
          </p>

          <p>
            Tiffany got a slot to Worlds! I am so happy and excited for her.
            She's battled through so much crap in the past year. To get to be
            here to witness her redemption race capped off with a world
            championship slot? I'm elated for her.
          </p>

          <p>
            Unfortunately, Caitlin missed getting her slot by just one athlete
            and six minutes. For all the work she's put in through the winter, I
            hate the way that this played out. I don't know what was going
            through her head. But for me, it's much harder to be that close to a
            goal than to miss by a wide margin. Six freaking minutes.
          </p>

          <p>
            I spent the whole winter thinking about what would happen today. In
            all that time, I never envisioned a scenario where only one of them
            would punch a ticket to Worlds. And I <em>never</em> imagined a
            scenario where I would find <em>myself</em> getting a slot during the roll down.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/finland-slot.jpg"
                alt=""
              />
            </figure>
          </div>

          <p className="pb-4">
            I'm so glad DJ had his camera. It was a surreal blur in the moment.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/katy-hug.jpg"
                alt=""
              />
            </figure>
          </div>

          <p className="pb-4">
            We are all in different age groups. It's not like I took Caitlin's
            slot, but this was bittersweet nonetheless. This is not what the
            photo was supposed to be:
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/chatt703-2023-race-report/tif-frank-me.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            All winter, Tiffany and Caitlin wore blue wristbands that said
            Finland on them as reminders of what they were working toward.
          </p>

          <p>
            They got back to our Airbnb before I did. When I walked in the door,
            Caitlin was waiting for me. She hugged me, told me how excited she
            was for me, and then said she wanted me to have her Finland
            wristband.
          </p>

          <p className="pb-4">
            All the feels. Again. What an incredible and selfless gesture. I
            will never forget it.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/finland.jpg" alt="" />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Next Race</h1>
          <p className="pb-4">
            We'd long planned a team visit to Chattanooga Whiskey the day after
            the race, but I admittedly had my mind elsewhere on what was going
            on with Mark down in ATL.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/chatt703-2023-race-report/whiskey.jpg" alt="" />
            </figure>
          </div>

          <p>
            Things had gotten even more dire. We weren't talking about months or
            even weeks now. It was only a matter of days. I pondered just
            driving south for Atlanta instead of heading home to Nashville, but
            I wasn't packed for a longer trip.
          </p>

          <p>
            As I drove home, I thought about everything that's happened. From my
            two rounds of COVID, to Katy's seizure, to layoffs at work, and now
            Mark's cancer diagnosis? How in the world did the race go as well as
            it did?
          </p>

          <p>
            The following day, I got back in the car heading for Atlanta. The
            last time I had been to St. Joseph's Hospital was in middle school
            when my mom had a heart attack. I wasn't thrilled to be back.
          </p>

          <p>
            On the elevator ride up, I found myself biting my lip, trying to
            hold my shit together. Thankfully, I visited him with a couple of
            other friends. They helped deflect the growing lump in my throat. I
            think we were with him for about half an hour. There came a point
            where it was apparent he needed to rest, and we should head out.
          </p>

          <p>
            As we were all about to leave, he asked me to hang behind for a
            minute. Gulp.
          </p>

          <p>
            Mark told me I looked good with shaved legs, wanted me to kick ass
            in Finland, and told me he loved me. He asked for a hug, and then we
            said goodbye.
          </p>

          <p>He passed away a week later on June 1st.</p>

          <p>Life is fragile.</p>

          <p>
            Up next: the Ironman 70.3 World Championship on August 27 in Lahti,
            Finland. It'll be the trip of a lifetime.
          </p>

          <div className="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
